@media screen and (max-device-width: 480px) and (orientation: portrait) {
  body {
    /*
     * Positioning of floating player in mobile.
     */
    .jw-flag-touch.jw-flag-floating .jw-wrapper {
      top: 0;
      transform: translateY(6.2rem) !important;
      transition-property: transform;
      transition-duration: 0.3s;
    }

    &.has-trending-links {
      .jw-flag-touch.jw-flag-floating .jw-wrapper {
        transform: translateY(10.8rem) !important;
        top: 0;
      }
    }

    &.top-bar-hidden {
      .jw-flag-touch.jw-flag-floating .jw-wrapper {
        transform: translateY(0rem) !important;
        top: 0;
      }
    }

    /*
     * Z-index of floating player in mobile when menu is open.
     */
    &.menu-open .jw-flag-floating .jw-wrapper {
      z-index: 35;
    }

    /**
     * JW Player has a top margin in mobile portrait that we do not want.
     */
    @media screen and (max-device-width: 480px) and (orientation: portrait) {
      .jw-flag-touch.jw-flag-floating .jw-wrapper {
        margin-top: 0 !important;
      }
    }
  }
}
